<script setup>

import { onMounted, ref } from "vue"
import { useFxgtStore } from "@/store/fxgt"
import { useRoute } from 'vue-router'

import FormFxgt from "@/components-uhas/forms/FormFxgt.vue"

const fxgtStore = useFxgtStore()
const route = useRoute()

const fxgt = ref({ _: false })
const loading = ref(false)

onMounted(() => {
	loading.value = true
	fxgtStore.show(route.params.id).then(({ data }) => {
		if (data.error) {
			return
		}

		fxgt.value = { ...data.fxgt }

		loading.value = false
	})
})

</script>

<template>
	<div class="block-section">
		<div class="block-header">
				<span class="block-title">
					<div>Show #{{ fxgt.id }}</div>
				</span>

				<div class="flex align-items-center justify-content-center">
					<Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
				</div>
		</div>
		<div class="block-content">
			<div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
				<div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
					<div>

						<FormFxgt
							v-if="fxgt._ !== false"
							v-model="fxgt"
						/>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>
